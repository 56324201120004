import styled from "styled-components";

export const PageStyledWrapper = styled.div`
  width: 100%;
`;

export const PageContainer = styled.div`
  width: 100%;
`;

export const WhatIsContent = styled.div`
  width: 100%;
`;

export const StyledTitle = styled.div`
  width: 100%;
  text-align: center;
  font-size: 80px;
  font-weight: 700;
  line-height: 70px;
  color: #A4966E;
  margin-bottom: 40px;
  font-family: The Nautigal;

  @media screen and (max-width: 768px) {
    margin-bottom: 0;
    margin-top: 16px;
    font-size: 40px;

  }

`;

export const WhatIsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 4%;
  overflow: hidden;
  
  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;

  }

`;

export const ServicesContainer = styled.div`

position: relative; 
background-image: url("/services/backgroud-service.png");
background-position: center top;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  width: 100%;

> * {
  position: relative; 
  z-index: 2;
  width:100%
}

@media screen and (max-width: 768px) {
  background-image: url("/services/bg-mobile.png");

}
`;

export const ServiceTitle = styled.div`
font-size: 80px;
font-weight: 700;
line-height: 96px;
text-align: left;
color: #FFFFFFCC;
padding-top: 200px;
text-align: center;
font-family: The Nautigal;

@media screen and (max-width: 1440px) {
  padding-top: 140px;

}


`;

export const ServiceContent = styled.div`

`;

export const ServiceBlock = styled.div`

`;

export const QuickOrderContainer = styled.div`
max-width: 1440px;
width: 100%;
display: flex;
justify-content: start;
gap: 20px;
margin: 40px 0;
flex-direction: column;
height: 90px;
.PhoneInput {
  background-color: white;
  padding: 13px 20px;
  border-radius: 50px;
  box-shadow: 0px 4px 4px 0px #00000040;
  font-family: DM Sans;
  font-size: 18px;
  font-weight: 500;
  line-height: 23.44px;

  @media screen and (max-width: 768px) {
    width: 83%;

}
  }

.PhoneInputInput {
    border: none;
    outline: none;
    background: transparent;
    font-size: 18px;
    color: black; /* Цвет текста */
    padding-left: 10px;
    width: 100%;
  }

  .PhoneInputCountry {
    display: flex;
    align-items: center;
    padding-left: 10px;
  }

  .PhoneInputCountrySelect {
    border: none;
    background: transparent;
    outline: none;
    font-size: 18px;
    cursor: pointer;
  }

@media screen and (max-width: 768px) {
  flex-direction  :column ;
  align-items: center;
}

`;

export const Input = styled.input`
  border: 2px solid #fff;
  background-color: #E9D59C;
  border-radius: 50px;
  padding: 10px 20px;
  color: black;
  outline: none;
  width: 26%;
  text-align: center; 
  font-family: DM Sans;
  font-size: 18px;
  font-weight: 700;
  line-height: 23.44px;

  ::placeholder {
    color: black; 
  }

  @media screen and (max-width: 768px) {
    width: 80%;
    border: 4px solid #fff;

}
  
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
justify-content: center;
gap: 20px;

@media screen and (max-width: 768px) {
  flex-direction: column;
align-items:center
}
`;

export const MessageContainer = styled.div`
  display: flex;
justify-content: center;
font-family: DM Sans;
font-size: 20px;
font-weight: 500;
line-height: 23.44px;
text-align: left;

@media screen and (max-width: 768px) {

}
`;

export const Button = styled.button`
  background-color: #fff;
  border: none;
  border-radius: 50px;
  padding: 10px 20px;
  margin-left: 10px;
  color: #000;
  cursor: pointer;
  font-weight: bold;
  width: 26%;
  box-shadow: 0px 4px 4px 0px #00000040;
  font-family: DM Sans;
  font-size: 18px;
  font-weight: 500;
  line-height: 23.44px;

  &:hover:not(:focus) {
  background-color: #f2f2f2;
}

@media screen and (max-width: 768px) {
    width: 83%;
    margin-left: 0;

}
`;

export const VideoContainer = styled.div`
width: 100%;
display: flex;
flex-direction: row;
justify-content: center;

@media screen and (max-width: 768px) {
  flex-direction: column;

}
`;


export const WhatFoodOrder = styled.div`
width: 100%;
padding: 40px 0;
display: block;

@media screen and (max-width: 768px) {
display:none

}
`;

export const LeftImageWhatOrder = styled.img`
position: absolute;
height: 384px;
transform: translateY(46px);

`;

export const WhatOrderContent = styled.div`
display: flex;
flex-direction: row;
justify-content: center;
gap: 40px;
width: 1440px;

`;

export const WhatOrderInfo = styled.div`
width: 29%;
font-family: DM Sans;
font-size: 17px;
font-weight: 500;
line-height: 30.23px;
text-align: left;
display: flex;
flex-direction: column;
justify-content: center;

`;

export const WhatOrderImageContainer = styled.div`
display: flex;
flex-direction: row;
width: 48%;
justify-content: space-around;

`;

export const WhatOrderSmallImageContainer = styled.div`
display: flex;
flex-direction: column;

`;

export const WhatOrderMainImage = styled.img`
height: 486px;

@media screen and (max-width: 1440px) {
  height: 400px;
    position: relative;
    top: 35px;
}
`;

export const WhatOrderSmallFirstImage = styled.img`
height: 206px;
margin-bottom: 60px;

`;

export const WhatOrderSmallSecondImage = styled.img`
height: 206px;

`;

export const WhatOrderInfoContainer = styled.div`
width: 100%;
display: flex;
flex-direction: row;
justify-content: center;

`;

export const PhotoContainer = styled.div`
width: 100%;

`;

export const WhatFoodOrderMobile = styled.div`
display:none;

@media screen and (max-width: 768px) {
  width: 100%;
padding: 40px 0;
display: block;
}
`;

export const WhatOrderInfoContainerMobile = styled.div`
width: 100%;
display: flex;
flex-direction: column;
justify-content: center;

`;

export const WhatOrderInfoMobile = styled.div`
width: 100%;
font-family: DM Sans;
font-size: 14px;
font-weight: 500;
line-height: 18.23px;
text-align: center;
display: flex;
flex-direction: column;
justify-content: center;
padding: 0 38px;

`;

export const WhatOrderImageMobile = styled.img<{ toTop: number }>`
position: relative;
height: 180px;
bottom: ${props => props.toTop}px;

`;

export const WhatOrderImageContainerMobile = styled.div`
display: flex;
flex-direction: column;
width: 100%;
justify-content: space-around;
padding-bottom: 10px;

`;

export const WhatOrderImageSectionMobile = styled.div`
display: flex;
flex-direction: row;
width: 100%;
justify-content: space-between;

`;

export const WhatOrderMobileContainer = styled.div`
height: 230px;
display: flex;
justify-content: end;
flex-direction: column;

`;

export const VideoContent = styled.video`
height: 100vh;

@media screen and (max-width: 768px) {
  width: 100%;
  height: auto;

}

`;

export const VideoBlock = styled.div`
box-shadow: 0px 4px 30px 30px #00000040;
height:100vh;

@media screen and (max-width: 768px) {
  height:auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
`;

export const ServiceItem = styled.div<{ isVisible: boolean }>`
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;

  ${({ isVisible }) =>
    isVisible &&
    `
      opacity: 1;
      transform: translateY(0);
  `}

&:last-child{
  padding-bottom: 120px;
}
`;