import React, { ReactElement, useEffect, useRef, useState } from "react";

import { ServiceBlock, ServiceContent, ServiceItem, ServicesContainer, ServicesStyledWrapper, ServiceTitle } from "./Services.styled";
import { Service } from "../../components/shared-components/Service/Service";
import { services_MOCK } from "../../dbMock/services";
import { useLocation } from "react-router-dom";

interface ServicesPageProps { }

export const ServicesPage = (props: ServicesPageProps): ReactElement => {
  const { } = props;
  const location = useLocation();
  const [id, setId] = useState<string>("0");
  const [visibleItems, setVisibleItems] = useState<boolean[]>([]);


  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const idParam = params.get("id");
    setId(idParam || "0");
  }, [location]);


  return (
    <ServicesStyledWrapper>
      <ServiceTitle>Services</ServiceTitle>
      <ServicesContainer>
        <ServiceContent>
          <ServiceBlock >
            {services_MOCK.map((item: any, index: number) => {
              return <ServiceItem
                key={index}
                data-index={index}
                className="service-item"
                isVisible={visibleItems[index] || true}
              >
                <Service id={id} key={item.id} index={index} item={item} href={""} isFull={true} />
              </ServiceItem>
            })}
          </ServiceBlock>
        </ServiceContent>
      </ServicesContainer>
    </ServicesStyledWrapper>
  );
};
