import { Link } from "react-router-dom";
import styled from "styled-components";
export const NavigationStyledWrapper = styled.div``
  ;

export const NavigationContainer = styled.div``
  ;
export const NavigationBackgroundImageContainer = styled.div<{ height: number }>`
position: relative; 
background-image: url("/testbgnav.png");
background-position: bottom;
background-repeat: no-repeat;
background-size: 100% 100%;  //cover
display: flex;
justify-content: center;
width: auto;
height: ${({ height }) => height}px; 

> * {
  position: relative; 
  z-index: 2;
}

@media screen and (max-width: 1440px) {
  &::before {
  top: -12px;

}
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const NavigationContentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;



export const NavigationInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 607px;
  width: 100%;
  max-width: 1440px;
  margin: 50px 0;

  @media screen and (max-width: 1440px) {
    max-width: 1220px;

  }
`;

export const TitleContainer = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
width: 25%;
z-index: 10;

@media screen and (max-width: 1440px) {
    padding-top: 50px;

  }
  
`;

export const Title = styled.div`
  color: #E9D59C;
  font-size: 82px;
  font-weight: 700;
  line-height: 96.29px;
  letter-spacing: 0.1em;
  text-align: left;
  font-family: Rockwell Std;

  @media screen and (max-width: 768px) {
    font-size: 42px;
  line-height: 49.32px;
  letter-spacing: 0.1em;
  text-align: left;
  }
  `;

export const Text = styled.div`
  color: white;
  font-size: 23px;
  font-weight: 500;
  line-height: 29.95px;
  text-align: left;
  margin-bottom: 30px;
  font-family: DM Sans;
  width: 90%;

  @media screen and (max-width: 768px) {
    font-size: 14px;
    font-weight: 700;
    line-height: 18.23px;
    text-align: left;

  }
`;

export const TextDetails = styled.div`
  color: white;
  font-size: 18px;
  font-weight: 500;
  line-height: 23.44px;
  text-align: left;
  font-family: DM Sans;
  width: 90%;

  @media screen and (max-width: 768px) {
font-size: 14px;
font-weight: 400;
line-height: 18.23px;
text-align: left;

  }
`;

export const ImageFirstContainer = styled.img`
  position: absolute;
  width: 390px;
  height: 271px;
  top: 0;
  right: 0;
  z-index: 2; 

  @media screen and (max-width: 1440px) {

}

`;

export const ImageSecondContainer = styled.img`
  position: absolute;
  width: auto;
  height: 480px;
  top: 340px;
  right: 0;
  z-index: 2; 
`;

export const MainImage = styled.img`
  position: relative;
  width: auto;
  height: auto;
  z-index: 2; 
  left: -6%;
  top: 8px;
  @media screen and (max-width: 1440px) {
    left: 9%;

  }
  //top: -2px;
`;

export const MainImageContainer = styled.div`
width: 29%;
z-index: 9;
`;

export const GroupImagesContainer = styled.div`
display: flex;
flex-direction: column;
height: 120%;
padding-left: 30px;

@media screen and (max-width: 1440px) {
  position: relative;
  left: 96px;
  }

`;

export const FirstImageGroup = styled.img`
  position: relative;
  top: -84px;
  width: max-content;
  height: 225px;
  z-index: 200;
`;

export const SecondImageGroup = styled.img`
  position: relative;
  top: -130px;
  left: 60%;
  width: 240px;
  width: max-content;
  height: 258px;
  z-index: 200;
`;

export const OrderButton = styled(Link)`
  background-image: url("https://dream-picnic.sfo3.cdn.digitaloceanspaces.com/PICNIC/header-image/test.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 230px;
  height: 245px;
  top:-150px;
  right: 18px;
  z-index: 200;
  position: relative;
  display: flex;
  justify-content: right;
  align-items: center;
  font-size: 18px;

`;

export const TextOrder = styled.div`
display: flex;
flex-direction: column;
font-weight: 500;
font-family: DM Sans;
font-size: 18px;
line-height: 23.44px;

@media screen and (max-width: 768px) {
  font-size: 12px;
  font-weight: 500;
  line-height: 15.62px;

  }

`;

export const TextOrderContainer = styled.div`
display: flex;
flex-direction: row;
padding-right: 18px;

`;


export const NavigationBackgroundImageContainerMobile = styled.div<{ height: number }>`
    display: none;



  @media screen and (max-width: 768px) {
    position: relative; 
    background-image: url("/background-image-first-mobile.png");
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: 100% 100%;  //cover
    display: flex;
    justify-content: center;
    width: auto;
    height: ${({ height }) => height}px; 
    overflow: hidden;
    > * {
      position: relative; 
      z-index: 2;
    }
  }
`;


export const NavigationContentContainerMobile = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ImageSecondContainerMobile = styled.img`
  position: absolute;
  width: auto;
  height: 142px;
  top: 34px;
  right: 0;
  z-index: 2; 
`;

export const NavigationInfoContainerMobile = styled.div`
  display: flex;
  flex-direction: row;
  height: 345px;
  width: 100%;
  max-width: 1440px;
  margin: 17px 0;

`;

export const TitleContainerMobile = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: start;
    width: 220px;
    z-index: 10;
    margin-left: 13px;
    padding-top: 12px;

  
`;

export const MainImageContainerMobile = styled.div`
width: 29%;
z-index: 9;
position: absolute;
`;

export const MainImageMobile = styled.img`
    position: relative;
    width: auto;
    height: auto;
    z-index: 2;
    left: 188px;
    top: -6px;
    height: 137px;
`;

export const OrderButtonMobile = styled(Link)`
    background-image: url("https://dream-picnic.sfo3.cdn.digitaloceanspaces.com/PICNIC/header-image/test.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 120px;
    height: 135px;
    top: 143px;
    left: 250px;
    z-index: 200;
    position: absolute;
    display: flex;
    justify-content: right;
    align-items: center;
    font-size: 12px;

`;

export const SecondImageMobile = styled.img`
    position: absolute;
    top: 240px;
    left: 198px;
    width: auto;
    height: 87px;
    z-index: 300;
`;

export const FirstImageMobile = styled.img`
    position: absolute;
    top: 263px;
    left: 318px;
    width: auto;
    height: 75px;
    z-index: 300;
`;