import React, { ReactElement } from "react";

import { AboutContainer, AboutInfo, AboutInfoContainer, AboutStyledWrapper, AboutTitle } from "./About.styled";

interface AboutPageProps { }

export const AboutPage = (props: AboutPageProps): ReactElement => {
  const { } = props;

  return (
    <AboutStyledWrapper>
      <AboutContainer>
        <AboutTitle>About</AboutTitle>
        <AboutInfoContainer>
          <AboutInfo>Welcome to Dream picnic where we transform your dreams into reality with luxury picnics in the most stunning locations across Hawaii. We curate bespoke experiences that go beyond the ordinary, offering you the chance to dine surrounded by the island's unparalleled beauty. Whether it's a sunset feast overlooking the ocean, a private brunch in a lush tropical oasis, or a romantic evening beneath the stars, our picnics are designed to create memories that last a lifetime. </AboutInfo>
          <AboutInfo>Every detail is meticulously crafted to ensure that your experience is as magical as the setting itself. From exquisite decor to gourmet delights, we blend elegance with the natural splendor of Hawaii to bring you moments of pure bliss. Join us at Dream picnic and let us create a celebration of life's most beautiful moments in paradise. </AboutInfo>
        </AboutInfoContainer>
      </AboutContainer>
    </AboutStyledWrapper>
  );
};
