import { Link } from "react-router-dom";
import styled from "styled-components";

export const ServiceStyledWrapper = styled.div`
padding: 15px 0;

`;

export const ServiceContainer = styled.div`
  display: block;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const ServiceContainerMobile = styled.div`
  display: none;

  @media screen and (max-width: 768px) {
    display: block;
  }
`;

export const ServiceContent = styled.div`
display:flex;
flex-direction: row;


`;

export const ServiceImage = styled.div`
display: flex;
flex-direction: row;
width: 45%;

@media screen and (min-width: 1980px) {
  justify-content: center;
}

@media screen and (max-width: 768px) {
  width: 100%;
}
`;

export const ServiceInfo = styled.div`
width: 55%;
display: flex;
align-items: center;

@media screen and (max-width: 768px) {
  width: 92%;
  flex-direction: column;
  gap: 10px;
}

`;

export const ServiceImageSmall = styled.div`
display:flex;
flex-direction:column;
`;

export const ServiceImageMain = styled.div`
  height:790px;
  
  @media screen and (max-width: 1440px) {
    height: 675px;
  }
`;

export const FirstImageService = styled.img`
  position: relative;
  width: max-content;
  height: 260px;
  left: 130px;

  @media screen and (max-width: 1440px) {
    height: 221px;
  }

  @media screen and (max-width: 768px) {
    left: 0px;
    width: 150px;
    height: auto;
  }
`;

export const SecondImageService = styled.img`
  position: relative;
  width: max-content;
  height: 260px;

  @media screen and (max-width: 1440px) {
    height: 221px;
  }

  @media screen and (max-width: 768px) {
    width: 150px;
    height: auto;
  }
`;

export const ThirdImageService = styled.img`
  position: relative;
  width: max-content;
  height: 260px;
  left: 130px;
  
  @media screen and (max-width: 1440px) {
    height: 221px;
  }

  @media screen and (max-width: 768px) {
    left: 0px;
    width: 150px;
    height: auto;
  }
`;

export const ServiceMainImage = styled.img`
  position: relative;
  width: auto;
  height: 386px;
    top:25%;
    left: 50px;

    @media screen and (max-width: 1440px) {
      height: 330px;
    }
`;

export const ServiceImageRight = styled.div`
display: flex;
flex-direction: row;
justify-content: end;
width: 45%;

@media screen and (min-width: 1980px) {
  justify-content: center;
}
`;

export const ServiceImageSmallRight = styled.div`
display:flex;
flex-direction:column;
`;

export const FirstImageServiceRight = styled.img`
  position: relative;
  width: max-content;
  height: 260px;
  right: 130px;

  @media screen and (max-width: 1440px) {
    height: 221px;
  }
`;

export const SecondImageServiceRight = styled.img`
  position: relative;
  width: max-content;
  height: 260px;

  @media screen and (max-width: 1440px) {
    height: 221px;
  }
`;

export const ThirdImageServiceRight = styled.img`
  position: relative;
  width: max-content;
  height: 260px;
  right: 130px;

  @media screen and (max-width: 1440px) {
    height: 221px;
  }
`;

export const ServiceImageMainRight = styled.div`
  height:790px;
  
  @media screen and (max-width: 1440px) {
    height: 675px;
  }
`;

export const ServiceMainImageRight = styled.img`
  position: relative;
  width: auto;
  height: 386px;
  top:25%;
  right: 50px;

  @media screen and (max-width: 1440px) {
  height: 330px;

 }
`;

export const ServiceInfoRight = styled.div`
width: 55%;
display: flex;
align-items: center;
`;

export const ServiceInfoContainer = styled.div<{isDefault:boolean}>`
width: 100%;
color: #fff;
background: rgba(0, 0, 0, 0.5);
padding: ${({ isDefault }) => isDefault ? "30px 80px 30px 30px" : "30px 30px 30px 80px"};
border-radius: ${({ isDefault }) => isDefault ? "60px 0px 0px 60px" : "0px 60px 60px 0px"};
display: flex;

@media screen and (min-width: 1980px) {
  justify-content: ${({isDefault}) => isDefault ? "left" : "right"};
}
`;

export const ServiceTitle = styled.div`
font-family: Rockwell Std;
font-size: 25px;
font-weight: 700;
line-height: 29.36px;
text-align: left;
margin-bottom: 20px;

@media screen and (max-width: 768px) {
  text-align: center;
  font-size: 19px;
  line-height: 22.31px;

}
`;

export const ServiceDescroption = styled.p`
font-family: DM Sans;
font-size: 18px;
font-weight: 500;
line-height: 23.44px;
text-align: left;
margin-bottom: 20px;

.desription{
  color: #4E94B2;
  font-family: DM Sans;
  font-size: 18px;
  font-weight: 700;
  line-height: 23.44px;
}

@media screen and (max-width: 768px) {
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  line-height: 18.23px;


  .desription{
  color: #4E94B2;
  font-family: DM Sans;
  font-size: 15px;
  font-weight: 700;
  line-height: 19.53px;

}
}
`;

export const AmenitiesBlock = styled.span`
  color: #4E94B2;
  font-family: DM Sans;
  font-size: 18px;
  font-weight: 700;
  line-height: 23.44px;

  @media screen and (max-width: 768px) {
  text-align: center;
  font-size: 15px;
  font-weight: 700;
  line-height: 19.53px;


}

`;

export const ServiceAmenities = styled.p`
font-family: DM Sans;
font-size: 18px;
font-weight: 500;
line-height: 23.44px;
text-align: left;

@media screen and (max-width: 768px) {
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  line-height: 18.23px;
}
`;


export const ServiceButton = styled(Link)`
display: flex;
justify-content: center;
align-items: center;
width: 270px;
height: 44px;
border-radius: 28px;
background-color: white;

color: black;
font-family: DM Sans;
font-size: 18px;
font-weight: 700;
line-height: 23.44px;
text-align: center;
transition: 0.3s;
&:hover{
  background-color: #E9D59C;
}

@media screen and (max-width: 768px) {
  width: 92%;
font-size: 14px;
font-weight: 700;
line-height: 18.23px;
text-align: center;


}
@media (hover:none){
    &:active{
      color: black;
      text-shadow: 0px 5px 5px 0px #00000040;
    }}

`;


export const ServiceInfoBlock = styled.div`
width: 44vw;

`;

export const PriceContainer = styled.div`
font-family: DM Sans;
font-size: 20px;
font-weight: 500;
line-height: 23.44px;
text-align: left;

@media screen and (max-width: 768px) {
  text-align: center;
  font-size: 15px;
  font-weight: 700;
  line-height: 19.53px;
}
`;


export const PriceContent = styled.div`
display: flex;
flex-direction: row;
  margin-top: 20px;
  margin-bottom: 20px;
  gap: 5px;

  @media screen and (max-width: 768px) {
justify-content: center;
margin-top: 12px;
margin-bottom: 0px;
}
`;


export const ServiceContentContainer = styled.div`

`;

export const ServiceContentMobile = styled.div`
display:flex;
flex-direction: column;
justify-content: center;
align-items:center;

`;

export const ServiceImageMobile = styled.div`
display:flex;
flex-direction:column;
align-items: center;
`;

export const ServiceInfoContainerMobile = styled.div`
width: 100%;
color: #fff;
background: rgba(0, 0, 0, 0.5);
padding: 30px 30px 15px 30px;
border-radius: 60px ;
display: flex;
text-align: center;
flex-direction: column;


`;

export const ServiceInfoBlockMobile = styled.div`
width: 100%;

`;


export const ServiceButtonOrder = styled.button`
display: flex;
justify-content: center;
align-items: center;
width: 270px;
height: 44px;
border-radius: 28px;
background-color: white;
cursor:pointer;
color: black;
font-family: DM Sans;
font-size: 18px;
font-weight: 700;
line-height: 23.44px;
text-align: center;
transition: 0.3s;
border: none;

@media screen and (max-width: 768px) {
  width: 92%;
font-size: 14px;
font-weight: 700;
line-height: 18.23px;
text-align: center;

}

@media (hover:hover){

&:hover{
    background-color: #E9D59C;
  }
}

@media screen and (max-width: 768px){
    font-size: 14px;

    @media (hover:none){
    &:active{
      color: black;
    }
  }
}
`;