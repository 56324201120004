import styled from "styled-components";

export const AppStyledWrapper = styled.div`
  width: 100%;
  background-color: #E9D59C;
`;

export const AppContentStyledWrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  `;
