export const what_is_MOCK: any[] = [
  {
    id: "1",
    title: "What is this?",
    text: "Dream Picnic creates unforgettable, luxurious outdoor experiences with a personal touch. We combine elegant settings with nature’s beauty to bring you a picture-perfect picnic, just like the one shown here. Whether you’re celebrating a special occasion or simply enjoying a day out, our picnic setups include beautifully curated decor, cozy seating, and a custom ambiance designed for relaxation and enjoyment.",
    imageUrl: "/what-is/what-is-1.png",
    imgUrlMobile: "/what-is/what-is-1-mobile.png"
  },
  {
    id: "2",
    title: "How it works?",
    text: `At Dream Picnic, we take care of every detail so you can focus on creating memories. Here’s how we turn your picnic dreams into reality:

<strong>1. Pick Your Dream</strong>  
   Choose from a variety of thoughtfully designed themes, each tailored to different moods, styles, and occasions.

<strong>2. Select the Perfect Setting</strong>  
   Whether it's a scenic beach, a lush garden, or your favorite park, we bring your dream picnic to the location of your choice.

<strong>3. Arrive to Magic</strong>  
   Picture walking into a beautifully arranged picnic, complete with elegant decor, plush cushions, and all the little touches that make the moment special.

<strong>4. Relax and Savor</strong>  
   Enjoy the moment with your loved ones, surrounded by beauty, comfort, and nature.All you have to do is sit back, relax, and make the most of it.

<strong>5. Leave the Rest to Us</strong>  
   When you're ready to leave, we handle all the packing and cleanup, allowing you to simply walk away with the memories.`,
    imageUrl: "/what-is/what-is-2.png",
    imgUrlMobile: "/what-is/what-is-2-mobile.png"

  },
  {
    id: "3",
    title: "Where does this happen?",
    text: `<strong>Welcome to Dream Picnic – Where Memories Blossom</strong>  
    
    Escape the ordinary and step into a world of luxury and relaxation with Dream Picnic. We specialize in curating unforgettable outdoor experiences, designed to make any moment magical. Whether you’re celebrating a birthday, an anniversary, or simply enjoying time with friends, we transform your favorite outdoor spot into a dreamy, picture-perfect setting.  

With personalized touches, elegant décor, and seamless service, all you have to do is show up, relax, and create lasting memories.At Dream Picnic, we handle every detail from setup to cleanup, so you can savor every second of your unique experience.

<strong>Your Dream Picnic Awaits – Let’s Create It Together.</strong> `,
    imageUrl: "/what-is/what-is-3.png",
    imgUrlMobile: "/what-is/what-is-3-mobile.png"

  },
]