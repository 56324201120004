import React, { ReactElement } from "react";

import { HomeLink, LogoImage, LogoImageContainer, LogoStyledWrapper } from "./Logo.styled";

interface LogoProps {
  size?: string;
}

export const Logo = (props: LogoProps): ReactElement => {
  const { size = "normal" } = props;


  return (
    <LogoStyledWrapper >
      <HomeLink to="/">
        <LogoImageContainer>
          {size === "small" ?
            <LogoImage src={"/dream-picnic-logo.png"} alt="Logo" width={39} height={43} />
            :
            <LogoImage src={"/dream-picnic-logo.png"} alt="Logo" width={72} height={79} />
          }
        </LogoImageContainer>
      </HomeLink>
    </LogoStyledWrapper>
  );
};
