import { Link } from "react-router-dom";
import styled from "styled-components";

export const HeaderMobileStyledWrapper = styled.div`
width: 100%;
`;

export const HeaderContainerMobile = styled.div``;

export const HeaderContentMobile = styled.div`
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-inline: 20px;
    background: linear-gradient(0deg, rgba(29, 29, 29, 0.0625) 0%, rgba(29, 29, 29, 0.25) 100%);
    height: 55px;
`;
export const HeaderMenuButton = styled.div`
    width:25%;
`;

export const HeaderMobileContainer = styled.a`
    width:25%;
    font-family: PT Sans;
    font-size: 15px;
    font-weight: 700;
    line-height: 19.41px;
    text-align: left;
    color: white;

    @media (hover:none){
    &:active{
      color: #4E94B2;
      text-shadow: 0px 5px 5px 0px #00000040;
    }
  }
`;

export const LogoContainer = styled.div`
    width: 50%;

`;



export const MobileMenuOverlay = styled.div<{ menuOpen: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 55px;
  transition: transform 0.3s ease-in-out;
  transform: ${({ menuOpen }) => (menuOpen ? "translateX(0)" : "translateX(-100%)")}; /* Выезжание слева */

`;

export const MobileMenuContent = styled.div<{ menuOpen: boolean }>`
  background-color: #00000099;
  width: 100%;
  max-width: 100%;
  height: auto;
  text-align: center;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  transition: transform 0.3s ease;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  li {
    font-family: DM Sans;
    font-size: 15px;
    font-weight: 700;
    line-height: 19.53px;
    text-align: center;
    color: white;

  }
`;


export const MobileMenuOptionContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;

`;

export const MenuOption = styled(Link)`
    font-family: DM Sans;
    font-size: 15px;
    font-weight: 700;
    line-height: 19.53px;
    text-align: center;
    color: white;

    @media ( hover: none) {
      &:active{
      color: #4E94B2;
      text-shadow: 0px 5px 5px 0px #00000040;
    }
}
`;
