import React, { ReactElement, useState } from "react";

import { AddOnsExitButton, AddonsModalStyledWrapper, AddOnsOderButton, ButtonContainer, CheckBoxContainer, CheckBoxItem, CheckBoxLabel, ModalContent, ModalStyled, ModalText, ModalTitle, RxCross2Custom, TitleBlock } from "./AddonsModal.styled";
import { MdOutlineCheckBoxOutlineBlank } from "react-icons/md";
import { MdOutlineCheckBox } from "react-icons/md";
import { RxCross2 } from "react-icons/rx";
import Modal from 'react-modal'; // Импортируйте Modal из 'react-modal'

// Установите элемент приложения
Modal.setAppElement('#root'); 
interface AddonsModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
  onNext: (selected: string[]) => void;  // Передаём выбранные чекбоксы в родительский компонент
  item: any;

}

const addonsOptions = [
  { id: "fresh-flowers", label: "Fresh flowers" },
  { id: "charcuterie-board", label: "Charcuterie board" },
  { id: "beach-umbrella", label: "Beach umbrella" },
  { id: "cake-castumized", label: "Cake castumized" },
  { id: "lei-flower", label: "Lei flower" },
  { id: "mini-photo-shoot", label: "15 min personal mini photo shoot with your device board" },
  { id: "speaker-jbl", label: "Speaker jbl" },
  { id: "unique-breakfast", label: "Unique breakfast in botanical garden  board" },
  { id: "catered-meal", label: "Catered meal" }
];


export const AddonsModal = (props: AddonsModalProps): ReactElement => {
  const { isOpen, onRequestClose, onNext, item } = props;
  const [selectedAddons, setSelectedAddons] = useState<string[]>([]);

  const handleCheckboxChange = (addon: string) => {
    setSelectedAddons((prev: any) =>
      prev.includes(addon) ? prev.filter((a: any) => a !== addon) : [...prev, addon]
    );
  };

  return (
    <ModalStyled
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className="modal-check"
      style={{
        content: {
          zIndex: 10000,
        },
        overlay: {
          zIndex: 10000,
          backgroundColor: 'rgba(0, 0, 0, 0.25)',
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          overflow: "auto"  /* азрешаем прокрутку содержимого */
        },
      }}>
      <ModalContent>
        <TitleBlock>
          <ModalTitle>Picnic Add-ons</ModalTitle>
          <RxCross2Custom className="exit-button" onClick={onRequestClose} />
        </TitleBlock>
        <ModalText>Enhance your picnic with these delightful add-ons
          for an unforgettable experience.</ModalText>
        <CheckBoxContainer>
          {addonsOptions.map(option => (
            <CheckBoxItem key={option.id} onClick={() => handleCheckboxChange(option.label)}>
              {selectedAddons.includes(option.label) ? (
                <MdOutlineCheckBox size={24} style={{ flexShrink: "0" }} />
              ) : (
                <MdOutlineCheckBoxOutlineBlank size={24} style={{ flexShrink: "0" }} />
              )}
              <CheckBoxLabel htmlFor={option.id}>{option.label}</CheckBoxLabel>
            </CheckBoxItem>
          ))}
        </CheckBoxContainer>
        <ButtonContainer>
          <AddOnsOderButton onClick={() => onNext(selectedAddons)}>ORDER</AddOnsOderButton>
        </ButtonContainer>
      </ModalContent>
    </ModalStyled>
  );
};
