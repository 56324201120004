import React, { ReactElement } from "react";

import { Line, LineShadow, PolygonShadow, PolygonShadowContainer, WhatIsContent, WhatIsId, WhatIsInfo, WhatIsStyledWrapper, WhatIsText, WhatIsTitle } from "./WhatIs.styled";

interface WhatIsProps {
  id: string;
  imgUrl: string;
  imgUrlMobile: string;
  title: string;
  text: string;
}

export const WhatIs = (props: WhatIsProps): ReactElement => {
  const { id, imgUrl, title, text, imgUrlMobile } = props;

  return (
    <WhatIsStyledWrapper>
      <PolygonShadowContainer>
        <PolygonShadow src="what-is/background-dark-svg.svg" />
      </PolygonShadowContainer>
      <LineShadow />
      <WhatIsContent imageUrl={imgUrl} imgUrlMobile={imgUrlMobile}>
        <WhatIsInfo>
          <WhatIsId>{id}</WhatIsId>
          <WhatIsTitle>{title}</WhatIsTitle>
        </WhatIsInfo>
        <Line />
      </WhatIsContent>
      <WhatIsText dangerouslySetInnerHTML={{ __html: text }} />

    </WhatIsStyledWrapper>
  );
};
