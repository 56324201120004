import React, { ReactElement, useEffect, useState } from "react";

import { AmenitiesBlock, FirstImageService, FirstImageServiceRight, PriceContainer, PriceContent, SecondImageService, SecondImageServiceRight, ServiceAmenities, ServiceButton, ServiceButtonOrder, ServiceContainer, ServiceContainerMobile, ServiceContent, ServiceContentContainer, ServiceContentMobile, ServiceDescroption, ServiceImage, ServiceImageMain, ServiceImageMainRight, ServiceImageMobile, ServiceImageRight, ServiceImageSmall, ServiceImageSmallRight, ServiceInfo, ServiceInfoBlock, ServiceInfoBlockMobile, ServiceInfoContainer, ServiceInfoContainerMobile, ServiceInfoRight, ServiceMainImage, ServiceMainImageRight, ServiceStyledWrapper, ServiceTitle, ThirdImageService, ThirdImageServiceRight } from "./Service.styled";
import { AddonsModal } from "../AddonsModal/AddonsModal";
import { OrderFormModal } from "../OrderFormModal/OrderFormModal";

interface ServiceInterface {
  id: string;
  name: string;
  description: string[],
  amenties: string[],
  startingAt: string,
  image1Url: string,
  image2Url: string,
  image3Url: string,
  imageMainUrl: string
}

interface ServiceProps {
  id?: string;
  item: ServiceInterface;
  index: number;
  href: string;
  isFull?: boolean;
}

export const Service = (props: ServiceProps): ReactElement => {
  const { id, index, item, href, isFull } = props;
  const [isAddonsOpen, setAddonsOpen] = useState(false);
  const [isOrderFormOpen, setOrderFormOpen] = useState(false);

  const [selectedAddons, setSelectedAddons] = useState<string[]>([]);

  const openAddonsModal = () => setAddonsOpen(true);
  const closeAddonsModal = () => setAddonsOpen(false);

  const openOrderFormModal = (selected: string[]) => {
    setSelectedAddons(selected);
    setAddonsOpen(false);
    setOrderFormOpen(true);
  };

  const closeOrderFormModal = () => setOrderFormOpen(false);
  const backToAddonsModal = () => {
    setOrderFormOpen(false);
    setAddonsOpen(true);
  };


useEffect(() => {
  if (id !== '0' && isFull === true) {
    const timer = setTimeout(() => {
      const isMobile = window.innerWidth <= 768; // Проверяем, мобильное ли устройство
      const targetId = isMobile ? `${id}-mobile` : id; // Добавляем суффикс к id, если мобильная версия

      const element = document.getElementById(targetId!);
      
      // Проверяем, что элемент существует и не скрыт
      const isVisible = element && window.getComputedStyle(element).display !== 'none';
      
      if (element && isVisible) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }, 100); // Настройте задержку при необходимости
  
    return () => clearTimeout(timer);
  }
}, [id, isFull]);

  return (
    <ServiceStyledWrapper>
      <ServiceContentContainer>
        <ServiceContainer>
          {index % 2 === 0 ?
            <ServiceContent id={item.id}>
              <ServiceImage>
                <ServiceImageSmall>
                  <FirstImageService src={item.image1Url} />
                  <SecondImageService src={item.image2Url} />
                  <ThirdImageService src={item.image3Url} />
                </ServiceImageSmall>
                <ServiceImageMain>
                  <ServiceMainImage src={item.imageMainUrl} />
                </ServiceImageMain>
              </ServiceImage>
              <ServiceInfo>
                <ServiceInfoContainer isDefault={true}>
                  <ServiceInfoBlock>
                    <ServiceTitle>{item.name}</ServiceTitle>
                    {item.description.map((desc: string, i: number) => {
                      return <ServiceDescroption key={i}>{i === 0 ? <span className="desription">DESCRIPTION:</span> : null} {desc}</ServiceDescroption>
                    })}
                    {isFull && (
                      <>
                        <AmenitiesBlock className="description">AMENITIES INCLUDED:</AmenitiesBlock>
                        {item.amenties.map((desc: string, i: number) => {
                          return <ServiceAmenities key={i}> {desc}</ServiceAmenities>
                        })}
                        <PriceContent>
                          <AmenitiesBlock className="description">STARTING AT:</AmenitiesBlock> <PriceContainer>{item.startingAt}</PriceContainer>
                        </PriceContent>
                      </>
                    )}

                    {isFull ?
                      <ServiceButtonOrder onClick={openAddonsModal}>
                        ORDER
                      </ServiceButtonOrder>
                      :
                      <ServiceButton to={`${href}?id=${item.id}`}>
                        MORE
                      </ServiceButton>
                    }
                  </ServiceInfoBlock>
                </ServiceInfoContainer>
              </ServiceInfo>
            </ServiceContent>
            :
            <ServiceContent id={item.id}>
              <ServiceInfoRight>
                <ServiceInfoContainer isDefault={false}>
                  <ServiceInfoBlock>
                    <ServiceTitle>{item.name}</ServiceTitle>
                    {item.description.map((desc: string, i: number) => {
                      return <ServiceDescroption key={i}>{i === 0 ? <span className="desription">DESCRIPTION:</span> : null} {desc}</ServiceDescroption>
                    })}
                    {isFull && (
                      <>
                        <AmenitiesBlock className="description">AMENITIES INCLUDED:</AmenitiesBlock>
                        {item.amenties.map((desc: string, i: number) => {
                          return <ServiceAmenities key={i}> {desc}</ServiceAmenities>
                        })}
                        <PriceContent>
                          <AmenitiesBlock className="description">STARTING AT:</AmenitiesBlock> <PriceContainer>{item.startingAt}</PriceContainer>
                        </PriceContent>
                      </>
                    )}
                    {isFull ?
                      <ServiceButtonOrder onClick={openAddonsModal}>
                        ORDER
                      </ServiceButtonOrder>
                      :
                      <ServiceButton to={`${href}?id=${item.id}`}>
                        MORE
                      </ServiceButton>
                    }
                  </ServiceInfoBlock>
                </ServiceInfoContainer>
              </ServiceInfoRight>
              <ServiceImageRight>
                <ServiceImageMainRight>
                  <ServiceMainImageRight src={item.imageMainUrl} />
                </ServiceImageMainRight>
                <ServiceImageSmallRight>
                  <FirstImageServiceRight src={item.image1Url} />
                  <SecondImageServiceRight src={item.image2Url} />
                  <ThirdImageServiceRight src={item.image3Url} />
                </ServiceImageSmallRight>
              </ServiceImageRight>

            </ServiceContent>
          }

        </ServiceContainer>

        <ServiceContainerMobile>
          <ServiceContentMobile id={`${item.id}-mobile`}>
            <ServiceImageMobile >
              <div style={{ marginBottom: "-30px" }}>
                <FirstImageService src={item.image1Url} />
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: "20px", marginBottom: "15px" }}>
                <SecondImageService src={item.image2Url} />
                <ThirdImageService src={item.image3Url} />
              </div>
            </ServiceImageMobile>
            <ServiceInfo>
              <ServiceInfoContainerMobile >
                <ServiceInfoBlockMobile>
                  <ServiceTitle>{item.name}</ServiceTitle>
                  {item.description.map((desc: string, i: number) => {
                    return <ServiceDescroption key={i}>{i === 0 ? <span className="desription">DESCRIPTION:</span> : null} {desc}</ServiceDescroption>
                  })}
                  {isFull && (
                    <>
                      <AmenitiesBlock className="description">AMENITIES INCLUDED:</AmenitiesBlock>
                      {item.amenties.map((desc: string, i: number) => {
                        return <ServiceAmenities key={i}> {desc}</ServiceAmenities>
                      })}
                      <PriceContent>
                        <AmenitiesBlock className="description">STARTING AT:</AmenitiesBlock> <PriceContainer>{item.startingAt}</PriceContainer>
                      </PriceContent>
                    </>
                  )}

                </ServiceInfoBlockMobile>

              </ServiceInfoContainerMobile>
              <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                {isFull ?
                  <ServiceButtonOrder onClick={openAddonsModal}>
                    ORDER
                  </ServiceButtonOrder>
                  :
                  <ServiceButton to={`${href}?id=${item.id}`}>
                    MORE
                  </ServiceButton>
                }
              </div>
            </ServiceInfo>
          </ServiceContentMobile>
        </ServiceContainerMobile>
      </ServiceContentContainer>

      <div style={{ position: "relative", zIndex: "100000" }}>
        <AddonsModal
          isOpen={isAddonsOpen}
          onRequestClose={closeAddonsModal}
          onNext={openOrderFormModal}
          item={item}
        />
        <OrderFormModal
          isOpen={isOrderFormOpen}
          onRequestClose={closeOrderFormModal}
          onBack={backToAddonsModal}
          item={item}  
          selectedAddons={selectedAddons}  
        />
      </div>
    </ServiceStyledWrapper>
  );
};
