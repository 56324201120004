import styled from "styled-components";

export const WhatIsStyledWrapper = styled.div`
    width:340px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 51px 0;

    @media screen and (max-width: 768px) {
      padding: 10px 0;
      width: 100%;

    }
`;

export const WhatIsContent = styled.div<{imageUrl:string, imgUrlMobile:string}>`
  position: relative;
  background-image: url(${props => props.imageUrl});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  width: 299px;
  height: 322px;
  color: #E9D59C;
  
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("/what-is/Vector.png");
    background-position: bottom;
    background-repeat: no-repeat;
    z-index: 100;
  }

  @media screen and (max-width: 768px) {
    position: relative;
    background-image: url(${props => (props.imgUrlMobile)});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    width: 230px;
    height: 282px;
    color: #E9D59C;
  
    &::after {
    background-image: url("/what-is/Vector-mobile.png");

  }
    }
`;


export const WhatIsText = styled.div`
margin-top:35px;
font-size: 18px;
font-weight: 500;
line-height: 23.44px;
text-align: left;
font-family: DM Sans;
white-space: pre-line;

@media screen and (max-width: 768px) {
    padding: 0 10% 0 10%;
    text-align: center;
    font-size: 14px;
    line-height: 18.23px;
    margin-top:15px;

  }
`;

export const Line = styled.div`
position: absolute;
width: 474.45px;
height: 0px;
top: 50%;

border: 2px solid #FFFFFF;
transform: matrix(-0.88, -0.48, 0.5, -0.86, 0, 0);
z-index: 200;

  @media screen and (max-width: 768px) {
    transform: matrix(-0.857, -0.515, 0.515, -0.857, 0, 0);
    top: 51%;
    width: 340.45px;
    box-shadow: -6px -16px 4px 0px #00000040;

    }
`;

export const LineShadow = styled.div`
position: relative;
width: 480px;
height: 0px;
top: 13%;
left: 0%;
border: 2px solid rgb(0,0,0, 0.1); 
  box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.1); 
 transform: matrix(-0.88, -0.48, 0.5, -0.86, 0, 0);
z-index: 0;

@media screen and (max-width: 768px) {
  display: none;

   }
`;


export const PolygonShadow = styled.img`
  position: absolute;
  top: 109%; 
  width: 299px;
  height: 325px;
  z-index: 0; 
`;

export const PolygonShadowContainer = styled.div`
    left: -147px;
    position: relative;
    top: -12px;

    @media screen and (max-width: 768px) {
    display:none;

    }
`;

export const WhatIsInfo = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: start;
    padding-top: 58%;
    width: 54%;
    z-index: 200;
    
  @media screen and (max-width: 768px) {
    padding-top: 65%;

  }
`;


export const WhatIsId = styled.div`
font-size: 50px;
font-style: italic;
font-weight: 700;
line-height: 58.72px;
text-align:center;
font-family: Rockwell Std;

  @media screen and (max-width: 768px) {
    font-size: 50px;
    font-style: italic;

  }
`;

export const WhatIsTitle = styled.div`
font-size: 23px;
font-weight: 400;
line-height: 27.01px;
text-align:center;
font-family: Rockwell Std;

  @media screen and (max-width: 768px) {
    font-size: 19px;
    line-height: 22.31px;
    
  }
`;