import React, { ReactElement, useEffect, useRef } from "react";
import Slider from "react-slick";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";

import { VideoBlock, VideoCarouselStyledWrapper, VideoContent, VideoContentMobile, VideoDecstop, VideoMobile } from "./VideoCarousel.styled";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
interface VideoCarouselProps { }

export const VideoCarousel = (props: VideoCarouselProps): ReactElement => {
  const { } = props;
  const videoRefs = useRef<(HTMLVideoElement | null)[]>([]);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        const video = entry.target as HTMLVideoElement;
        if (entry.isIntersecting) {
          video.play();
        } else {
          video.pause();
        }
      });
    });

    videoRefs.current.forEach((video) => {
      if (video) {
        observer.observe(video);
      }
    });

    return () => {
      videoRefs.current.forEach((video) => {
        if (video) {
          observer.unobserve(video);
        }
      });
    };
  }, []);


  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    lazyLoad: 'ondemand' as 'ondemand',
    beforeChange: (current: number, next: number) => {
      const poster = next < videoRefs.current.length
        ? videoRefs.current[next]?.poster
        : null;

      if (poster) {
        const img = new Image();
        img.src = poster;
      }
    },
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
    ],
  };

  return (
    <VideoCarouselStyledWrapper>
      <VideoDecstop>
        <Slider {...settings} >
          <div style={{ width: "100%" }}>
            <VideoContent src="https://dream-picnic.sfo3.digitaloceanspaces.com/PICNIC/video-1.mp4"
              poster="https://dream-picnic.sfo3.cdn.digitaloceanspaces.com/PICNIC/poster-dec.png"
              autoPlay
              muted
              loop
              playsInline
              preload="auto"
              controls={false}></VideoContent>
          </div>
          <div style={{ width: "100%" }}>
            <VideoContent src="https://dream-picnic.sfo3.digitaloceanspaces.com/PICNIC/test.mp4"
              poster="https://dream-picnic.sfo3.cdn.digitaloceanspaces.com/PICNIC/poster-dec.png"
              autoPlay
              muted
              loop
              playsInline
              preload="auto"
              controls={false}></VideoContent>
          </div>
          <div style={{ width: "100%" }}>
            <VideoContent src="https://dream-picnic.sfo3.digitaloceanspaces.com/PICNIC/video-3.mp4"
              poster="https://dream-picnic.sfo3.cdn.digitaloceanspaces.com/PICNIC/poster-dec.png"
              autoPlay
              muted
              loop
              playsInline
              preload="auto"
              controls={false}></VideoContent>
          </div>
          <div style={{ width: "100%" }}>
            <VideoContent src="https://dream-picnic.sfo3.digitaloceanspaces.com/PICNIC/video-4.mp4"
              poster="https://dream-picnic.sfo3.cdn.digitaloceanspaces.com/PICNIC/poster-dec.png"
              autoPlay
              muted
              loop
              playsInline
              preload="auto"
              controls={false}></VideoContent>
          </div>
        </Slider>
      </VideoDecstop>
      <VideoMobile>
        <Slider {...settings}>
          <div>
            <VideoContentMobile
              ref={(el) => (videoRefs.current[0] = el)}
              src="https://dream-picnic.sfo3.digitaloceanspaces.com/PICNIC/video-mobile-1.mp4"
              poster="https://dream-picnic.sfo3.cdn.digitaloceanspaces.com/PICNIC/video-mobile-1.jpg"
              autoPlay
              muted
              loop
              playsInline
              preload="auto"
              controls={false}
            />
          </div>
          <div>
            <VideoContentMobile
              ref={(el) => (videoRefs.current[1] = el)}
              src="https://dream-picnic.sfo3.digitaloceanspaces.com/PICNIC/video-mobile-2.mp4"
              poster="https://dream-picnic.sfo3.cdn.digitaloceanspaces.com/PICNIC/video-mobile-2.jpg"
              autoPlay
              muted
              loop
              playsInline
              preload="auto"
              controls={false}
            />
          </div>
          <div>
            <VideoContentMobile
              ref={(el) => (videoRefs.current[2] = el)}
              src="https://dream-picnic.sfo3.digitaloceanspaces.com/PICNIC/video-mobile-3.mp4"
              poster="https://dream-picnic.sfo3.cdn.digitaloceanspaces.com/PICNIC/video-mobile-3.jpg"
              autoPlay
              muted
              loop
              playsInline
              preload="auto"
              controls={false}
            />
          </div>
          <div>
            <VideoContentMobile
              ref={(el) => (videoRefs.current[3] = el)}
              src="https://dream-picnic.sfo3.digitaloceanspaces.com/PICNIC/video-mobile-4.mp4"
              poster="https://dream-picnic.sfo3.cdn.digitaloceanspaces.com/PICNIC/video-mobile-4.jpg"
              autoPlay
              muted
              loop
              playsInline
              preload="auto"
              controls={false}
            />
          </div>
          <div>
            <VideoContentMobile
              ref={(el) => (videoRefs.current[4] = el)}
              src="https://dream-picnic.sfo3.digitaloceanspaces.com/PICNIC/video-mobile-5.mp4"
              poster="https://dream-picnic.sfo3.cdn.digitaloceanspaces.com/PICNIC/video-mobile-5.jpg"
              autoPlay
              muted
              loop
              playsInline
              preload="auto"
              controls={false}
            />
          </div>
          <div>
            <VideoContentMobile
              ref={(el) => (videoRefs.current[5] = el)}
              src="https://dream-picnic.sfo3.digitaloceanspaces.com/PICNIC/video-mobile-6.mp4"
              poster="https://dream-picnic.sfo3.cdn.digitaloceanspaces.com/PICNIC/video-mobile-6.jpg"
              autoPlay
              muted
              loop
              playsInline
              preload="auto"
              controls={false}
            />
          </div>
          <div>
            <VideoContentMobile
              ref={(el) => (videoRefs.current[6] = el)}
              src="https://dream-picnic.sfo3.digitaloceanspaces.com/PICNIC/video-mobile-7.mp4"
              poster="https://dream-picnic.sfo3.cdn.digitaloceanspaces.com/PICNIC/video-mobile-7.jpg"
              autoPlay
              muted
              loop
              playsInline
              preload="auto"
              controls={false}
            />
          </div>
          <div>
            <VideoContentMobile
              ref={(el) => (videoRefs.current[7] = el)}
              src="https://dream-picnic.sfo3.digitaloceanspaces.com/PICNIC/video-mobile-8.mp4"
              poster="https://dream-picnic.sfo3.cdn.digitaloceanspaces.com/PICNIC/video-mobile-8.jpg"
              autoPlay
              muted
              loop
              playsInline
              preload="auto"
              controls={false}
            />
          </div>
          <div>
            <VideoContentMobile
              ref={(el) => (videoRefs.current[8] = el)}
              src="https://dream-picnic.sfo3.digitaloceanspaces.com/PICNIC/video-mobile-9.mp4"
              poster="https://dream-picnic.sfo3.cdn.digitaloceanspaces.com/PICNIC/video-mobile-9.jpg"
              autoPlay
              muted
              loop
              playsInline
              preload="auto"
              controls={false}
            />
          </div>
          <div>
            <VideoContentMobile
              ref={(el) => (videoRefs.current[9] = el)}
              src="https://dream-picnic.sfo3.digitaloceanspaces.com/PICNIC/video-mobile-10.mp4"
              poster="https://dream-picnic.sfo3.cdn.digitaloceanspaces.com/PICNIC/video-mobile-10.jpg"
              autoPlay
              muted
              loop
              playsInline
              preload="auto"
              controls={false}
            />
          </div>
          <div>
            <VideoContentMobile
              ref={(el) => (videoRefs.current[10] = el)}
              src="https://dream-picnic.sfo3.digitaloceanspaces.com/PICNIC/video-mobile-11.mp4"
              poster="https://dream-picnic.sfo3.cdn.digitaloceanspaces.com/PICNIC/video-mobile-11.jpg"
              autoPlay
              muted
              loop
              playsInline
              preload="auto"
              controls={false}
            />
          </div>
        </Slider>
      </VideoMobile>
    </VideoCarouselStyledWrapper>
  );
};
