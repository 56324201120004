import React, { ReactElement } from "react";

import { PhotoContainer, PhotoStyledWrapper } from "./Photo.styled";
import { Photo } from "../../components/shared-components/Photo/Photo";
import { mobile_photo_image_MOCK } from "../../dbMock/mobilePhoto";
import { photo_image_MOCK } from "../../dbMock/photo";

interface PhotoPageProps { }

export const PhotoPage = (props: PhotoPageProps): ReactElement => {
  const { } = props;

  return (
    <PhotoStyledWrapper>
      <PhotoContainer>
        <Photo title={"Photo"} photo={photo_image_MOCK} mobilePhoto={mobile_photo_image_MOCK} isFollow={false}/>
      </PhotoContainer>
    </PhotoStyledWrapper>
  );
};
