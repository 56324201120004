import { httpClient } from "../httpClient/HttpClient";

export class AboutUsApi {
  private ABOUTUS_ENDPOINT = "about";

  getAboutUs = async (): Promise<any> => {
    const data = await httpClient.get(`${this.ABOUTUS_ENDPOINT}/`);

    return data;
  };

  selectedOrder = async (items:any): Promise<any> => {
    const data = await httpClient.post(`${this.ABOUTUS_ENDPOINT}/description`, items);

    return data;
  };

  quickOrder = async (items:string): Promise<any> => {
    const data = await httpClient.post(`${this.ABOUTUS_ENDPOINT}/quick`, {items});

    return data;
  };
}

export const aboutUsApi = new AboutUsApi();
