import React from "react";
import { Route, Routes } from "react-router-dom";
import { Page } from "./pages/Page/Page";
import { Navigation } from "./components/shared-components/Navigation/Navigation";
import { AppContentStyledWrapper, AppStyledWrapper } from "./App.styled";
import { Footer } from "./components/composit-components/Footer/Footer";
import { ServicesPage } from "./pages/Services/Services";
import { AboutPage } from "./pages/About/About";
import { PhotoPage } from "./pages/Photo/Photo";

function App() {
  return (
    <AppStyledWrapper>
      <AppContentStyledWrapper>
        <Navigation />
        <Routes>
          <Route path="/" element={<Page />} />
          <Route path="/services" element={<ServicesPage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/photo" element={<PhotoPage />} />
          </Routes>
        <Footer />
      </AppContentStyledWrapper>
    </AppStyledWrapper>
  );
}

export default App;
