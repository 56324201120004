import React, { ReactElement, useState } from "react";
import { AddOnsOderButton, ButtonClose, ButtonContainer, DataContainer, ModalContent, ModalStyled, OptionName, SelectedAdd, StyledInput, StyledTextArea, TitleBlock } from "./OrderFormModal.styled";
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { aboutUsApi } from "../../../api/requests/about-us.api";
import DatePicker from "react-datepicker";
import { format } from 'date-fns';

interface OrderFormModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
  onBack: () => void;
  item: any;
  selectedAddons: string[];
}

interface FormData {
  name: string;
  email: string;
  phone: string;
  numberOfGuests: string;
  preferredDate: Date | null;
  picnicStartTime: Date | null;
  heardAboutUs: string;
  additionalInfo: string;
}

export const OrderFormModal = (props: OrderFormModalProps): ReactElement => {
  const { isOpen, onRequestClose, onBack, item, selectedAddons } = props;
  const [formData, setFormData] = useState<FormData>({
    name: "",
    email: "",
    phone: "",
    numberOfGuests: "",
    preferredDate: null,
    picnicStartTime: null,
    heardAboutUs: "",
    additionalInfo: ""
  });

  const [isNotificationModalOpen, setNotificationModalOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false); // Добавлено состояние для блокировки кнопки
  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  const validationRules: { [key: string]: (value: any) => string } = {
    name: (value: string) => {
      if (value.trim().length < 4) {
        return "Name must be at least 4 characters long.";
      }
      return "";
    },
    phone: (value: string) => {
      if (!value || value.trim().length === 0) {
        return "Phone number is required.";
      }
      return "";
    },
    preferredDate: (value: Date | null) => {
      if (!value) {
        return "Date is required.";
      }
      return "";
    },
    picnicStartTime: (value: Date | null) => {
      if (!value) {
        return "Start time is required.";
      }
      return "";
    }
  };

  const validateField = (name: string, value: any) => {
    if (validationRules[name]) {
      return validationRules[name](value);
    }
    return "";
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;

    const error = validateField(name, value);
    setErrors(prevErrors => ({
      ...prevErrors,
      [name]: error
    }));

    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));

  };

  const handleOrderClick = async () => {
    if (isSubmitting) return;

    const newErrors: { [key: string]: string } = {};
    let isValid = true;
    Object.keys(validationRules).forEach((key) => {
      const error = validateField(key, formData[key as keyof FormData]);
      if (error) {
        isValid = false;
        newErrors[key] = error;
      }
    });

    setErrors(newErrors);
    if (!isValid) {
      return;
    }

    setIsSubmitting(true);
    try {
      const formattedPreferredDate = formData.preferredDate
        ? format(formData.preferredDate, 'dd.MM.yyyy')
        : null;

      const formattedPicnicStartTime = formData.picnicStartTime
        ? format(formData.picnicStartTime, 'HH:mm')
        : null;

      const orderDetails = {
        ...formData,
        preferredDate: formattedPreferredDate?.replaceAll(".", "-"),
        picnicStartTime: formattedPicnicStartTime,
        item,
        selectedAddons
      };

      await aboutUsApi.selectedOrder(orderDetails);
      console.log(orderDetails);
      setNotificationModalOpen(true);
      onRequestClose();
    } catch (error) {
      console.error("Ошибка при отправке заказа", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleDateChange = (date: Date | null) => {

    const error = validateField("preferredDate", date);
    setErrors(prevErrors => ({
      ...prevErrors,
      preferredDate: error
    }));

    setFormData(prevData => ({
      ...prevData,
      preferredDate: date
    }));

  };

  const handleTimeChange = (time: Date | null) => {
    const error = validateField("picnicStartTime", time);
    setErrors(prevErrors => ({
      ...prevErrors,
      picnicStartTime: error
    }));

    setFormData(prevData => ({
      ...prevData,
      picnicStartTime: time
    }));

  };

  return (
    <>
      <ModalStyled
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        className="modal-check"
        style={{
          content: {
            zIndex: 10001,
            width: "100%"
          },

          overlay: {
            zIndex: 10001,
            backgroundColor: 'rgba(0, 0, 0, 0.25)',
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            overflow: "auto",
            width: "100%"
          },

        }}

      >
        <ModalContent>
          <TitleBlock>
            <OptionName>{item.name}</OptionName>
          </TitleBlock>
          <SelectedAdd>Selected add-ons: {selectedAddons.length === 0 ? "none" : selectedAddons.join(", ")}</SelectedAdd>
          <DataContainer>
            <StyledInput
              type="text"
              placeholder="Name, Last name*"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              maxLength={50}
            />
            {errors.name && <p className="error-message">{errors.name}</p>}
            <StyledInput
              type="email"
              placeholder="E-mail"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              maxLength={50}
            />
            <PhoneInput
              defaultCountry="US"
              value={formData.phone}
              onChange={(phone) => setFormData(prevState => ({
                ...prevState,
                phone: phone || ""
              }))}
              placeholder="Phone number*"
            />
            {errors.phone && <p className="error-message">{errors.phone}</p>}
            <StyledInput
              type="number"
              placeholder="Number of guests"
              name="numberOfGuests"
              className="number"
              value={formData.numberOfGuests}
              onChange={handleInputChange}
              min="0"
              max="300"

            />

            <DatePicker
              selected={formData.preferredDate}
              onChange={handleDateChange}
              dateFormat="dd.MM.yyyy"
              showYearDropdown
              scrollableYearDropdown
              yearDropdownItemNumber={15}
              showMonthDropdown
              placeholderText="Select date*"
              className="custom-datepicker"
            />
            {errors.preferredDate && <p className="error-message">{errors.preferredDate}</p>}

            <DatePicker
              selected={formData.picnicStartTime}
              onChange={handleTimeChange}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15}
              timeCaption="Time"
              dateFormat="HH:mm"
              placeholderText="Select time*"
              className="custom-timepicker"
            />

            {errors.picnicStartTime && <p className="error-message">{errors.picnicStartTime}</p>}
            <StyledInput
              type="text"
              placeholder="How did you hear about us?"
              name="heardAboutUs"
              value={formData.heardAboutUs}
              onChange={handleInputChange}
              maxLength={150}
            />

            <StyledTextArea
              placeholder="Share more information about your wishes"
              name="additionalInfo"
              value={formData.additionalInfo}
              onChange={handleInputChange}
              maxLength={300}
            ></StyledTextArea>

          </DataContainer>
          <ButtonContainer>
            <AddOnsOderButton onClick={handleOrderClick} disabled={isSubmitting}>
              {isSubmitting ? "Submitting..." : "ORDER"}
            </AddOnsOderButton>
            <AddOnsOderButton className="back-button" onClick={onBack}>BACK TO ADD-ONS</AddOnsOderButton>
          </ButtonContainer>
        </ModalContent>
      </ModalStyled>
      <ModalStyled
        isOpen={isNotificationModalOpen}
        onRequestClose={() => setNotificationModalOpen(false)}
        className="modal-notification"
        style={{
          content: {
            zIndex: 10001,
            width: "300px",
            textAlign: "center"
          },
          overlay: {
            zIndex: 10001,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          },
        }}
      >
        <ModalContent>
          <h2>Order placed successfully!</h2>
          <ButtonClose onClick={() => setNotificationModalOpen(false)}>Close</ButtonClose>
        </ModalContent>
      </ModalStyled>
    </>
  );
};