import styled from "styled-components";

export const PhotoStyledWrapper = styled.div`
display:flex;
justify-content:center;
`;

export const PhotoContainer = styled.div`
width: 1440px;
display: flex;
flex-direction: column;
margin-bottom:30px;
`;

export const Title = styled.div`
font-family: The Nautigal;
font-size: 80px;
font-weight: 700;
line-height: 96px;
text-align: left;
color: #0000004D;
text-align: center;
margin-bottom: 30px;


`;

export const GalleryImageContainer = styled.div`
display: flex;
flex-direction: row;
justify-content: center;
width: 100%;
padding-bottom: 10px ;

@media screen and (max-width: 720px) {
    display:none;
}
`;

export const GalleryImageContainerMobile = styled.div`
display:none;


@media screen and (max-width: 720px) {
    display: flex;
flex-direction: row;
justify-content: center;
width: 100%;
padding-bottom: 10px ;}
`;


export const GalleryBlockContainer = styled.div`
height: 720px;
flex-direction: column;
justify-content: space-between;
margin: 0 10px;
gap: 20px;
display: flex;

@media screen and (max-width: 1440px) {
    height: 560px;

}

@media screen and (max-width: 720px) {
    height: 280px;
}

&.third-block {
  @media screen and (max-width: 720px) {
    display: none;
  }
}
`;

export const ImageBigContainer = styled.div`
width: 410px;
height: 440px;



@media screen and (max-width: 1440px) {
    width: 336px;
    height: 336px;
}


@media screen and (max-width: 720px) {
    width: 134px;
    height: 160px;
}
`;

export const ImageSmallContiner = styled.div`
width: 410px;
height: 260px;

@media screen and (max-width: 1440px) {
    width: 336px;
    height: 204px;
}


@media screen and (max-width: 720px) {
    width: 134px;
    height: 100px;
}

`;

export const GalleryImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center; 


  &.top-left{
    border-radius: 40px 0px 0px 0px;

 }

 &.bottom-left{
    border-radius: 0px 0px 0px 40px;

 }

 &.top-right{
    border-radius: 0px 40px 0px 0px;

 }

 &.bottom-right{
    border-radius: 0px 0px 40px 0px;

 }

  @media screen and (max-width: 720px) {
    object-position: center 50%;
    }
`;

export const Button = styled.a`
  background-color: #fff;
  border: none;
  border-radius: 50px;
  padding: 10px 20px;
  font-size: 16px;
  color: #000;
  cursor: pointer;
  font-weight: bold;
  width: 26%;
  box-shadow: 0px 4px 4px 0px #00000040;
  font-family: DM Sans;
  text-align: center;
  &:hover:not(:focus) {
  background-color: #f2f2f2;


}
@media screen and (max-width: 768px) {
    width: 290px;
  }
`;