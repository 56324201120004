import { Link } from "react-router-dom";
import styled from "styled-components";

export const FooterStyledWrapper = styled.div`
width: 100%;
background: linear-gradient(180deg, #E9D59C 1.22%, #BBAC7F 34.22%, #454033 100%);
display: flex;
justify-content: center;
`;

export const FooterContainer = styled.div`
width: 1440px;
height: 150px;
display: flex;
align-items: center;
justify-content: center;

@media screen and (max-width: 720px) {
    width: 100%;
  }
`;

export const FooterItems = styled.div`
display:flex;
flex-direction: row;
gap: 50px;
`;

export const SocialLogoWrapper = styled.div`
display: flex;
justify-content: center;
align-items: center;
gap: 20px;

@media screen and (max-width: 720px) {
flex-direction:column;
gap: 15px;

}
`;

export const SocialLogoContainer = styled.a`
display: flex;
justify-content: center;
align-items: center;
`;


export const MenuContainer = styled.div`
display: flex;
justify-content: center;
align-items: center;
color: white;
gap: 22px;

@media screen and (max-width: 768px) {
    flex-direction:column;
    gap: 2px;

}
`;


export const MenuTitle = styled.div`
font-family: PT Sans;
font-size: 23px;
font-weight: 400;
line-height: 29.49px;

    @media screen and (max-width: 768px){
    font-size: 18px;
    }
`;


export const FooterLink = styled(Link)`
display: flex;
justify-content: center;
align-items: center;
padding-top: 5px;

font-family: PT Sans;
font-size: 18px;
font-weight: 400;
line-height: 23.08px;

@media (hover:hover){

&:hover{
      color: black;
      text-shadow: 0px 5px 5px 0px #00000040;
  }
}

@media screen and (max-width: 768px){
    font-size: 14px;

    @media (hover:none){
    &:active{
      color: black;
      text-shadow: 0px 5px 5px 0px #00000040;
    }
  }
}
`;

export const SocialIcon = styled.div`
  color: white;
  font-size: 26px; // размер по умолчанию для десктопа

  @media screen and (max-width: 768px) {
    font-size: 20px; // размер для мобилок
  }
`;