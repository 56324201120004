import styled from "styled-components";

export const AboutStyledWrapper = styled.div`
width:100%;
display: flex;
flex-direction: column;
align-items: center;
margin-top: 40px;
`;
export const AboutContainer = styled.div`
width:1440px;
display: flex;
flex-direction: column;

@media screen and (max-width: 768px) {
width: 100%;

  }
`;
export const AboutTitle = styled.div`
text-align: center;
font-family: The Nautigal;
font-size: 80px;
font-weight: 700;
line-height: 96px;
color: #0000004D;



`;
export const AboutInfoContainer = styled.div`
display: flex;
flex-direction: column;
gap:20px;
margin-top: 30px;

font-family: DM Sans;
font-size: 18px;
font-weight: 700;
line-height: 23.44px;
color: #000000;
align-items: center;

@media screen and (max-width: 768px) {
    font-size: 14px;
    font-weight: 700;
    line-height: 18.23px;
    margin-bottom: 10px;

  }
`;
export const AboutInfo = styled.p`
text-align: center;
width: 80%;
`;
