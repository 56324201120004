import React, { ReactElement } from "react";

import { FooterContainer, FooterItems, FooterLink, FooterStyledWrapper, MenuContainer, MenuTitle, SocialIcon, SocialLogoContainer, SocialLogoWrapper } from "./Footer.styled";
import { Logo } from "../../shared-components/Logo/Logo";
import { SlSocialInstagram } from "react-icons/sl";
import { SlSocialFacebook } from "react-icons/sl";
import { SiLinkedin } from "react-icons/si";
import { FaTiktok } from "react-icons/fa";

interface FooterProps { }

export const Footer = (props: FooterProps): ReactElement => {
  const { } = props;

  return (
    <FooterStyledWrapper>
      <FooterContainer>
        <FooterItems>
          <Logo />
          <SocialLogoWrapper>
            <SocialLogoContainer href="https://www.instagram.com/dream_picnic_hawaii?igsh=MWxxNTQ3d2ZicDFzcQ==" target="_blank">
              <SocialIcon as={SlSocialInstagram} />
            </SocialLogoContainer>
            <SocialLogoContainer href="https://www.facebook.com/profile.php?id=61566433137671&mibextid=LQQJ4d" target="_blank">
              <SocialIcon as={SlSocialFacebook} />
            </SocialLogoContainer>
            <SocialLogoContainer href="https://www.tiktok.com/@dream_picnic_hawaii?_t=8qd3RHaZ27S&_r=1" target="_blank">
              <SocialIcon as={FaTiktok} />
            </SocialLogoContainer>
          </SocialLogoWrapper>
          <MenuContainer>
            <MenuTitle>Menu</MenuTitle>
            <FooterLink to={"/services"}>
              Services
            </FooterLink>
            <FooterLink to={"/about"}>
              About
            </FooterLink>
            <FooterLink to={"/photo"}>
              Photo
            </FooterLink>
          </MenuContainer>
        </FooterItems>
      </FooterContainer>
    </FooterStyledWrapper>
  );
};
