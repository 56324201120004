import React, { ReactElement } from "react";

import { HeaderContainer, HeaderMobile, HeaderOption, HeaderStyledWrapper, LogoContainer, MobileLink } from "./Header.styled";
import { Logo } from "../../shared-components/Logo/Logo";

interface HeaderProps { }

export const menuItems = [
  { id: 1, name: "Home", link: "/" },
  { id: 1, name: "Services", link: "/services" },
  { id: 1, name: "About", link: "/about" },
  { id: 1, name: "Photo", link: "/photo" },
];

export const Header = (props: HeaderProps): ReactElement => {
  const { } = props;

  return (
    <HeaderStyledWrapper>
      <HeaderContainer>
        <LogoContainer>
          <Logo />
        </LogoContainer>
        {menuItems.map((item: any, index:number) => {
          return <HeaderOption key={index} to={item.link}>
            {item.name}
          </HeaderOption>
        })}
        <HeaderMobile>
          <MobileLink href="tel:+4444444444">+4444444444</MobileLink>
        </HeaderMobile>
      </HeaderContainer>
    </HeaderStyledWrapper>
  );
};
