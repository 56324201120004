import styled from "styled-components";
import Modal from "react-modal";
import { RxCross2 } from "react-icons/rx";

export const ModalContent = styled.div`
  position: relative;
  background: white;
  padding: 30px 50px 40px 50px;
  border-radius: 8px;
  max-width: 540px;
  width: 100%;
  max-height: 750px;
  height: 100%;
  margin: auto;
  background: #E9D59C;
  box-shadow: 0px 0px 40px 30px #0000008C;

  @media screen and (max-width: 768px) {
    width: 90%;
    padding: 20px 30px 20px 30px;


  }
`;

export const AddonsModalStyledWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000; /* Убедитесь, что это значение больше, чем у других элементов */
`;

export const ModalStyled = styled(Modal)`
  .ReactModal__Overlay {
    background-color: rgba(0, 0, 0, 0.25); /* Черный фон с прозрачностью 25% */
    z-index: 10000; /* Убедитесь, что это значение больше, чем у других элементов */
  }

  .ReactModal__Content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10001; 
    max-width: 540px; 
    width: 100%;
    max-height: 750px; 
    height: 100%;
    background: white; 
    padding: 20px;
    border-radius: 8px;
  }
`;

export const ModalTitle = styled.div`
    font-family: Rockwell Std;
    font-size: 40px;
    font-weight: 700;
    line-height: 46.97px;
    text-align: left;

    @media screen and (max-width: 768px) {
    font-size: 20px;
    font-weight: 700;
    line-height: 23.49px;
    text-align: left;



  }
`;

export const ModalText = styled.div`
font-family: DM Sans;
font-size: 18px;
font-weight: 400;
line-height: 23.44px;
text-align: left;
margin-bottom: 20px;

@media screen and (max-width: 768px) {
    font-size: 12px;
    font-weight: 400;
    line-height: 15.62px;
    text-align: left;

  }

`;

export const CheckBoxContainer = styled.div`
display: flex;
flex-direction: column;
margin-bottom: 20px;
gap:5px;
max-height: 500px; /* Фиксированная высота контейнера */
height: 100%;
overflow-y: auto; /* Вертикальная прокрутка, если содержимое не помещается */

@media screen and (max-width: 768px) {
    max-height: 300px; /* Фиксированная высота контейнера */


  }
`;

export const CheckBoxItem = styled.div`
cursor: pointer;

@media screen and (max-width: 768px) {
display: flex;
align-items: center;
flex-shrink: 0;

  }
`;

export const CheckBoxLabel = styled.label`
font-family: DM Sans;
font-size: 23px;
font-weight: 500;
line-height: 29.95px;
text-align: center;
padding-left: 10px;
cursor:pointer;

@media screen and (max-width: 768px) {
    font-size: 14px;
    font-weight: 500;
    line-height: 18.23px;
    text-align: left;
}

`;

export const ButtonContainer = styled.div`
display: flex;
flex-direction: column;
gap:10px;

`;

export const AddOnsOderButton = styled.button`
display: flex;
flex-direction: column;
width: 100%;
background: #4E94B2;
border-radius: 50px;
border: none;
height: 44px;
font-family: DM Sans;
font-size: 20px;
font-weight: 700;
line-height: 26.04px;
justify-content: center;
align-items: center;
cursor: pointer;
transition: background-color 0.3s ease; 
color: white;
box-shadow: 0px 4px 11px 0px #00000059;

&:hover{
    background: #3D7F9F;

}

@media screen and (max-width: 768px) {
font-size: 14px;
font-weight: 700;
line-height: 18.23px;
text-align: center;

}

@media (hover:none){
    &:active{
      color: black;
      text-shadow: 0px 5px 5px 0px #00000040;
    }}
`;

export const AddOnsExitButton = styled.button`
display: flex;
flex-direction: column;
width: 100%;
background: #FF5C5C;
border-radius: 50px;
border: none;
height: 44px;
font-family: DM Sans;
font-size: 20px;
font-weight: 700;
line-height: 26.04px;
justify-content: center;
align-items: center;
cursor: pointer;
transition: background-color 0.3s ease; 
color: white;
box-shadow: 0px 4px 11px 0px #00000059;

&:hover{
    background: #D34C4C;

}

@media screen and (max-width: 768px) {
font-size: 14px;
font-weight: 700;
line-height: 18.23px;
text-align: center;

}

@media (hover:none){
    &:active{
      color: black;
      text-shadow: 0px 5px 5px 0px #00000040;
    }}
`;

export const TitleBlock = styled.div`
display: flex;
flex-direction: row;
gap:10px;
justify-content: space-between;
margin-bottom: 15px;
align-items: center;
`;

export const RxCross2Custom = styled(RxCross2)`
font-size: 34px;
color: black;
cursor: pointer;
transition: color 0.3s ease, text-shadow 0.3s ease;

@media (hover:hover){

&:hover{
      color: red;
  }
}

@media screen and (max-width: 768px){
    font-size: 26px;

    @media (hover:none){
    &:active{
      color: black;
      text-shadow: 0px 5px 5px 0px #00000040;
    }
  }
}

`;