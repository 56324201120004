import React, { ReactElement, useState } from "react";

import { HeaderContainerMobile, HeaderContentMobile, HeaderMenuButton, HeaderMobileContainer, HeaderMobileStyledWrapper, LogoContainer, MenuOption, MobileMenuContent, MobileMenuOptionContainer, MobileMenuOverlay } from "./HeaderMobile.styled";
import { RiCloseFill, RiMenu2Fill } from "react-icons/ri";
import { Logo } from "../../shared-components/Logo/Logo";

interface HeaderMobileProps { }

export const HeaderMobile = (props: HeaderMobileProps): ReactElement => {
  const { } = props;

  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen((prev: any) => !prev);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  return (
    <HeaderMobileStyledWrapper>
      <HeaderContainerMobile>
        <HeaderContentMobile>

          <HeaderMenuButton onClick={toggleMenu}>
            {menuOpen ? (
              <RiCloseFill size={28} color="white" />
            ) : (
              <RiMenu2Fill size={28} color="white" />
            )}
          </HeaderMenuButton>

          <LogoContainer>
            <Logo size={"small"} />
          </LogoContainer>

          <HeaderMobileContainer href="tel:+4444444444">
            +4444444444
          </HeaderMobileContainer>

        </HeaderContentMobile>
      </HeaderContainerMobile>
      {/* Выезжающее меню */}
      <MobileMenuOverlay menuOpen={menuOpen} onClick={toggleMenu}>
        <MobileMenuContent menuOpen={menuOpen} onClick={(e) => e.stopPropagation()}>
          <MobileMenuOptionContainer>
            <MenuOption className="button" to={"/"} onClick={closeMenu}>Home</MenuOption>
            <MenuOption className="button" to={"/services"} onClick={closeMenu}>Services</MenuOption>
            <MenuOption className="button" to={"/about"} onClick={closeMenu}>About</MenuOption>
            <MenuOption className="button" to={"/photo"} onClick={closeMenu}>Photo</MenuOption>
          </MobileMenuOptionContainer>
        </MobileMenuContent>
      </MobileMenuOverlay>
    </HeaderMobileStyledWrapper>
  );
};
