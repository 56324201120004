import styled, { keyframes } from "styled-components";

const fadeInUp = keyframes`
  from {
    opacity: 0;
    transform: translateY(50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const ServicesStyledWrapper = styled.div``;



export const ServicesContainer = styled.div`

position: relative; 
background-image: url("/services/backgroud-service.png");
background-position: center top;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  width: 100%;
  padding-top: 160px;

> * {
  position: relative; 
  z-index: 2;
  width:100%
}

@media screen and (max-width: 768px) {
  background-image: url("/services/bg-mobile.png");
  padding-top: 90px;

}
`;

export const ServiceTitle = styled.div`
font-family: The Nautigal;
font-size: 80px;
font-weight: 700;
line-height: 96px;
text-align: center;
color: #0000004D;

@media screen and (max-width: 1440px) {

}

`;

export const ServiceContent = styled.div`

`;

export const ServiceBlock = styled.div`

`;

export const ServiceItem = styled.div<{ isVisible: boolean }>`
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;

  ${({ isVisible }) =>
    isVisible &&
    `
      opacity: 1;
      transform: translateY(0);
  `}

&:last-child{
  padding-bottom: 120px;
}
`;