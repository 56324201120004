import styled from "styled-components";

export const VideoCarouselStyledWrapper = styled.div`
  position: relative;
  box-shadow: 0px 4px 30px 30px #00000040;
  height: 100vh;

  @media screen and (max-width: 768px) {
    height: auto;
  }
  .slick-slider{
    width: 100%;
  }

  .slick-prev:before,
  .slick-next:before {
    color: black; 
  }

  .slick-prev,
  .slick-next {
    z-index: 2; 
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 24px;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .slick-prev {
    left: 10px; 
  }

  .slick-next {
    right: 10px; 
  }

  .slick-dots {
    z-index: 2; 
    bottom: 10px; 
  }
`;

export const VideoContentMobile = styled.video`
  width: 100%;
  height: 80vh;
  object-fit: cover;
  overflow: hidden;
  z-index: 1; 

  @media screen and (max-width: 768px) {
    width: 100%;
    height: 80vh;
    object-fit: cover;
  }
`;

export const VideoMobile = styled.div`
  display: none;

  @media screen and (max-width: 768px) {
    display: block;
    box-shadow: 0px 4px 30px 30px #00000040;
    height: 80vh;
    overflow: hidden;
    position: relative; 
  }
`;

export const VideoDecstop = styled.div`
  display: flex;
  box-shadow: 0px 4px 30px 30px #00000040;
  height: 100vh;
  position: relative;
  width: 100%;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const VideoContent = styled.video`
  height: 100vh;
  z-index: 1; 
  object-fit: cover;
  width: 100%;

  @media screen and (max-width: 768px) {
    width: 100%;
    height: auto;
  }
`;

export const VideoBlock = styled.div`
  box-shadow: 0px 4px 30px 30px #00000040;
  height: 100vh;
  z-index: 1; 

  @media screen and (max-width: 768px) {
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;
