import { Link } from "react-router-dom";
import styled from "styled-components";

export const LogoStyledWrapper = styled.div`
  cursor: pointer;
  @media screen and (max-width: 768px) {
      cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    }
`;

export const LogoImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LogoImage = styled.img`
    width: auto;
    
    @media screen and (max-width: 768px) {

    }
`;


export const HomeLink = styled(Link)`
  text-decoration: none;
  color: inherit;
`;