import { Link } from "react-router-dom";
import styled from "styled-components";

export const HeaderStyledWrapper = styled.div`
width:100%;
background: linear-gradient(0deg, rgba(29, 29, 29, 0.0625) 0%, rgba(29, 29, 29, 0.25) 100%);

  display: flex;
  justify-content: center;
  `;

export const HeaderContainer = styled.div`
  width: 1440px;
  display: flex;
  justify-content: start;
  padding: 6px 10px;
  gap:47px;
  align-items: center;
  
  @media screen and (max-width: 768px) {
    padding: 10px 50px;
    width: 100%;
  }
`;

export const HeaderOption = styled(Link)`
    color: white;
    text-align: center;
    font-family: DM Sans;
  font-size: 18px;
  font-weight: 700;
  line-height: 23.44px;


    &:hover{
    color: #4E94B2;
    text-shadow: 0px 5px 5px 0px #00000040;
    }
`;

export const HeaderMobile = styled.div`
    color: white;
    text-align: center;
    font-family: DM Sans;
  font-size: 18px;
  font-weight: 700;
  line-height: 23.44px;

`;


export const MobileLink = styled.a`
    color: white;
    text-align: center;

    
    &:hover{
      color: #4E94B2;
      text-shadow: 0px 5px 5px 0px #00000040;
  }
`;

export const LogoContainer = styled.div`
    margin-right: 50px;

`;
